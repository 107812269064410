
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { Actions } from "@/store/enums/StoreEnums";
import { sez, user_gst_type } from "@/core/data/genericData";
import Quill from "quill/dist/quill.js";
import { useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mask } from "vue-the-mask";
import { hide } from "@popperjs/core";
import JwtService from "@/core/services/JwtService";
export default defineComponent({
  name: "apps-profile-add",
  directives: { mask },
  components: {
    //AddTagsModal
  },
  methods: {
    onChange() {
      if (this.formData.pincode == "") {
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
      }
    },
    onChangeIndividualPin() {
      if (this.formData1.pincode == "") {
        this.formData1.city = "";
        this.formData1.state = "";
        this.formData1.country = "";
      }
    },

    onClearGst() {
      if (
        this.formData.gstin_search == "" ||
        this.formData.gstin_search !== "15"
      ) {
        this.formData.pincode = "";
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
        this.formData.jurisdiction = "";
        this.formData.last_name = "";
        this.formData.address_line_1 = "";
      }
    },
    onClearPan() {
      if (this.formData.pan_no == "" || this.formData.pan_no !== "10") {
        this.formData.pincode = "";
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
        this.formData.jurisdiction = "";
        this.formData.last_name = "";
        this.formData.address_line_1 = "";
      }
    },
    onClick() {
      (document.getElementById("id_data2") as HTMLInputElement).style.display =
        "block";
      (document.getElementById("id_data") as HTMLInputElement).style.display =
        "none";
      (document.getElementById("gst_id") as HTMLInputElement).style.display =
        "none";
      (
        document.getElementById("search_by_gst") as HTMLInputElement
      ).style.display = "none";
    },
    onCompanyClick() {
      (document.getElementById("id_data2") as HTMLInputElement).style.display =
        "none";
      (document.getElementById("id_data") as HTMLInputElement).style.display =
        "block";
      (document.getElementById("gst_id") as HTMLInputElement).style.display =
        "block";
      (
        document.getElementById("search_by_gst") as HTMLInputElement
      ).style.display = "block";
    },
  },

  setup() {
    const sez_data = sez;
    const user_gst_type_data = user_gst_type;
    const formRef = ref<null | HTMLFormElement>(null);
    const formRefIndividual = ref<null | HTMLFormElement>(null);
    const addNewsModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter();
    const shape = ref([]);

    onMounted(async () => {
      //const db_data = {}
      setCurrentPageBreadcrumbs("Add Employee", []);
      await setDropdownData();
      await setIndustryTypedata();
      await setBranchTitledata();
      await setBusinessTypedata();
      await setDepartmentdata();
      await setDesignationdata();
      await setROCdata();
    });

    const formData = ref({
      first_name: "",
      gendar_select: "",
      employee_code: "",
      pan_no: "",
      adhar_no: "",
      last_name: "",
      address_line_1: "",
      address_line_2: "",
      alternate_emails: "",
      personal_mobile: "",
      mobile_list: ref<any>([]),
      alternate_email_list: ref<any>([]),
      landline_list: ref<any>([]),
      personal_landline: "",
      anniversary_date_select: "",
      dob_select: "",
      joining_date_select: "",
      spouse_dob_select: "",
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
      official_email: "",
      address_type_select: "",
      designation_select: "",
      department_select: "",

      gstin_search: "",
      industry_type_select: "",
      website: "",
      business_type_select: "",
      sez: "",
      branch_name: "",
      branch_title_select: "",
      jurisdiction: "",
      name_of_person: "",
      mobile_select: "",
      email_select: "",
      landline_select: "",
      registrationNo: "",
      incorporationateDate: "",
      cinn: "",
      llpinn: "",
      dob: "",
      anniversary_dob: "",

      gstin: "",
      state_tin: "",
      gst_type: "",
    });

    const formData1 = ref({
      // individual_pincode : "",
      // individual_city : "",
      // individual_state : "",
      // individual_country : "",
      individual_spouse_dob: "",
      individual_anniversary_dob: "",
      individual_dob: "",
      individual_mobile_select: "",
      individual_email_select: "",
      individual_landline_select: "",
      individual_department_select: "",
      individual_address_line_1: "",
      individual_address_line_2: "",
      individual_designation_select: "",
      individual_name_of_person: "",
      individual_pan: "",
      pincode: "",
      pincode_id: 0,
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "First Name is required",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Last Name is required",
          trigger: "change",
        },
      ],
    });

    const loadingPan = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const address_type_data = ref([
      {
        id: 1,
        name: "Permanent",
      },
      {
        id: 2,
        name: "Residential",
      },
    ]);
    const getGstData = async () => {
      loadingPin.value = true;

      const db_data = { gstin: formData.value.gstin_search };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value.city = data["city_name"];
            formData.value.state = data["state_name"];
            formData.value.country = "India";
            formData.value.state_tin = data["tin"];
            formData.value.city_id = data["city_id"];
            formData.value.state_id = data["state_id"];
            formData.value.pincode_id = data["pincode_id"];
            formData.value.pincode = data["pincode"];
            formData.value.country_id = data["country_id"];
            formData.value.business_type_select = data["business_type_id"];
            formData.value.first_name = data["legal_business_name"];
            formData.value.last_name = data["legal_business_name"];
            formData.value.jurisdiction = data["state_jurisdiction"];
            formData.value.address_line_1 = data["address"];
            formData.value.gst_type = data["gst_type"];
            formData.value.gstin = data["gstin"];
            formData.value.state_tin = data["state_tin"];

            loadingPin.value = false;
          } else {
            formData.value.city = "";
            formData.value.state = "";
            formData.value.country = "";
            formData.value.state_tin = "";
            formData.value.city_id = "";
            formData.value.state_id = "";
            formData.value.pincode_id = "";
            formData.value.country_id = "";

            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pincode!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
              formData.value.city = data[i].city_name;
              formData.value.state = data[i].state_name;
              formData.value.country = data[i].country_name;
              formData.value.city_id = data[i].city_id;
              formData.value.state_id = data[i].state_id;
              formData.value.pincode_id = data[i].pincode_id;
              formData.value.country_id = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value.city = pin_details.city_name;
      formData.value.state = pin_details.state_name;
      formData.value.country = pin_details.country_name;
      formData.value.city_id = pin_details.city_id;
      formData.value.state_id = pin_details.state_id;
      formData.value.pincode_id = pin_details.pincode_id;
      formData.value.country_id = pin_details.country_id;
    };

    const pin1 = ref([]);
    const getPincodeData1 = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin1.value = data;

            for (let i = 0; i < data.length; i++) {
              formData1.value.city = data[i].city_name;
              formData1.value.state = data[i].state_name;
              formData1.value.country = data[i].country_name;
              formData1.value.city_id = data[i].city_id;
              formData1.value.state_id = data[i].state_id;
              formData1.value.pincode_id = data[i].pincode_id;
              formData1.value.country_id = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData1 = async (pin_details) => {
      formData1.value.city = pin_details.city_name;
      formData1.value.state = pin_details.state_name;
      formData1.value.country = pin_details.country_name;
      formData1.value.city_id = pin_details.city_id;
      formData1.value.state_id = pin_details.state_id;
      formData1.value.pincode_id = pin_details.pincode_id;
      formData1.value.country_id = pin_details.country_id;
    };

    const gendar_data = ref([]);
    const department_data = ref([]);
    const contact_designation_data = ref([]);
    const setDropdownData = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            gendar_data.value = body.gender_list;
            department_data.value = body.department_list;
            contact_designation_data.value = body.contact_designation_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const industry_types_data = ref([]);
    const setIndustryTypedata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            industry_types_data.value = body.industry_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const business_type = ref([]);
    const setBusinessTypedata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            business_type.value = body.business_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const department = ref([]);
    const setDepartmentdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            department.value = body.department_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const designation = ref([]);
    const setDesignationdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            designation.value = body.contact_designation_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const roc_list_data = ref([]);
    const setROCdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            roc_list_data.value = body.roc_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setEmployeeData = async (data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        employee_id: 0,
        contact_id: 0,
        employee_first_name: data.first_name,
        employee_last_name: data.last_name,
        company_employee_code: data.employee_code,
        employee_gender_id: data.gendar_select,
        employee_designation_id: data.designation_select,
        employee_department_id: data.department_select,
        office_email_id: data.official_email,
        employee_email_json: data.alternate_email_list,
        employee_mobile_json: data.mobile_list,
        employee_landline_json: data.landline_list,
        employee_dob: data.dob_select,
        employee_doj: data.joining_date_select,
        employee_anniversary_date: data.anniversary_date_select,
        employee_spouse_dob: data.spouse_dob_select,
        employee_pan: data.pan_no,
        employee_aadhar: data.adhar_no,
        address_type_id: data.address_type_select,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        pincode_id: Number(data.pincode_id),
        user_id: user.user_id,

        // company_id: 0,
        // company_name: data.first_name,
        // company_legal_name: data.last_name || "",
        // company_nob_id: data.gendar_select || "",
        // company_industry_type_id: data.industry_type_select || "",
        // company_business_type_id: data.business_type_select || "",
        // company_roc_id: data.roc_select || 1,
        // company_doi: data.incorporationateDate || "",
        // company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
        // company_registration_number: 1,
        // sez_yn: data.sez || "",
        // company_pan: "",
        // pan_verified_yn: data.pan_no !== "" ? 1 || data.pan_no : 0,
        // company_website: data.website || "",
        // branch_name: data.branch_name || "",
        // branch_title_type_id: data.branch_title_select || "",
        // business_email_json: data.alternate_email_list,
        // business_mobile_json: data.mobile_list,
        // business_landline_json: data.landline_list,
        // address_line_1: data.address_line_1 || "",
        // address_line_2: data.address_line_2 || "",
        // pincode_id: Number(data.pincode_id),
        // email: data.email_select,
        // mobile_no: data.mobile_select,
        // landline_no: data.landline_select,
        // user_id: user.user_id,
      };

      await store
        .dispatch(ActionsFi.CUST_EMPLOYEE_ADD, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            // UploadImage(data.category_id)
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Employee Information added successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-employee-list" });
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const setIndividualAdd = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        contact_name: data.individual_name_of_person,
        designation_id: data.individual_designation_select,
        email: data.individual_email_select,
        mobile_no: data.individual_mobile_select,
        landline_no: data.individual_landline_select,
        contact_dob: data.individual_dob,
        contact_anniversary_date: data.individual_anniversary_dob,
        contact_spouse_dob: data.individual_spouse_dob,
        contact_pan: data.individual_pan,
        pan_verified_yn: data.pan_no !== "" ? 1 || data.pan_no : 0,
        registration_no: "",
        address_line_1: data.individual_address_line_1,
        address_line_2: data.individual_address_line_2,
        pincode_id: Number(data.pincode_id),
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_ADD, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            // UploadImage(data.category_id)
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Individual Information created successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addNewsModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setEmployeeData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const onMobileEnter = () => {
      debugger;
      if (
        /^[789]\d{9}$/.test(formData.value["personal_mobile"]) &&
        !formData.value["mobile_list"].includes(
          formData.value["personal_mobile"]
        )
      ) {
        formData.value["mobile_list"].push(formData.value["personal_mobile"]);
        formData.value["personal_mobile"] = "";
      }
    };

    const onLandLineEnter = () => {
      debugger;
      if (
        /\d{5}([- ]*)\d{6}/.test(formData.value["personal_landline"]) &&
        !formData.value["landline_list"].includes(
          formData.value["personal_landline"]
        )
      ) {
        formData.value["landline_list"].push(
          formData.value["personal_landline"]
        );
        formData.value["personal_landline"] = "";
      }
    };

    const onEmailEnter = () => {
      debugger;
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.value["alternate_emails"]
        ) &&
        !formData.value["alternate_email_list"].includes(
          formData.value["alternate_emails"]
        )
      ) {
        formData.value["alternate_email_list"].push(
          formData.value["alternate_emails"]
        );
        formData.value["alternate_emails"] = "";
      }
    };

    const submitIndividual = async () => {
      loading.value = true;

      if (!formRefIndividual.value) {
        loading.value = false;
        return;
      }

      formRefIndividual.value.validate(async (valid) => {
        if (valid) {
          await setIndividualAdd(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      formData1,
      submitIndividual,
      rules,
      submit,
      formRef,
      formRefIndividual,
      loading,
      gendar_data,
      department_data,
      contact_designation_data,
      setDropdownData,
      industry_types_data,
      setIndustryTypedata,
      business_type,
      setBusinessTypedata,
      branch_title,
      setBranchTitledata,
      department,
      setDepartmentdata,
      designation,
      setDesignationdata,
      roc_list_data,
      setROCdata,
      addNewsModalRef,
      sez_data,
      user_gst_type_data,
      ClickPincodeData,
      ClickPincodeData1,
      getPincodeData,
      getPincodeData1,
      pin,
      pin1,
      loadingPin,
      loadingPan,
      getGstData,
      onMobileEnter,
      onEmailEnter,
      onLandLineEnter,
      address_type_data,
      router,
    };
  },
});
